<template>
  <div class="pa-5">
    <div class="text-center">
      <h2>Update Profile</h2>
    </div>
    <div class="space-30"></div>
    <v-card v-if="loading">
      <v-skeleton-loader
        v-bind="$attrs"
        type="table-heading, list-item-two-line, image"
      ></v-skeleton-loader>
    </v-card>
    <v-card v-if="isNotAuthorised">
      <v-container fluid>
        <v-row>
          <v-col>
            <label for="password">Please enter password to continue</label>
            <v-text-field
              v-model.trim="password"
              v-validate="'required'"
              type="password"
              class="form-control"
              name="password"
            ></v-text-field>
          </v-col> </v-row
      ></v-container>
      <v-btn
        text
        :disabled="!password"
        class="ma-2"
        color="primary"
        @click="checkAuthorization()"
      >
        Continue
      </v-btn>
    </v-card>
    <v-card v-if="!isNotAuthorised">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.name"
                label="User Name"
                :rules="[(v) => !!v || 'Name is required']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.password_hash"
                type="password"
                :rules="pwdRule"
                class="form-control"
                label="Enter New Password"
                name="password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.confirmPassword"
                :rules="pwdConfirmRule"
                type="password"
                class="form-control"
                name="confirmPassword"
                label="Confirm Password"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model.trim="item.email"
                label="Email"
                required
                :rules="[(v) => !!v || 'Email is required']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="mx-1 my-2">
            <v-col>
              <v-btn
                class="ma-2"
                :loading="loading"
                :disabled="loading || !isPasswordMatched"
                color="success"
                @click="validate"
              >
                <v-icon small left>mdi-check</v-icon>
                Save
              </v-btn>
            </v-col>
            <v-col>
              <v-btn text class="ma-2" color="primary" @click="backToProfile">
                Cancel
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <Snackbar
      class="mb-10"
      v-if="snackbar.show"
      :show="snackbar.show"
      :text="snackbar.text"
      :color="snackbar.color"
    ></Snackbar>
  </div>
</template>

<script>
import BaseService from "@/services/base.service.js";
import Snackbar from "@/components/common/Snackbar";
import { bus } from "@/main.js";
import { mapMutations } from "vuex";

export default {
  name: "ProfileEdit",
  components: {
    Snackbar,
  },
  data() {
    return {
      item: {},
      snackbar: {},
      loading: false,
      valid: true,
      isNotAuthorised: true,
      password: "",
      pwdRule: [
        (v) =>
          !v ||
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(
            v
          ) ||
          "Please enter password in correct format",
      ],
      pwdConfirmRule: [
        (v) => v === this.item.password_hash || "Passwords are not matching",
      ],
    };
  },
  computed: {
    isPasswordMatched() {
      return (
        !this.item.password_hash ||
        (this.item.confirmPassword &&
          this.item.confirmPassword === this.item.password_hash)
      );
    },
  },
  methods: {
    ...mapMutations("auth", ["updateUser"]),

    validate() {
      let result = this.$refs.form.validate();
      if (result) {
        this.save();
      }
    },
    backToProfile() {
      this.$router.push("/profile");
    },
    async save() {
      this.loading = true;
      let service = new BaseService("users");
      let res;

      if (!this.item.password_hash) {
        delete this.item["password_hash"];
      }

      res = await service.patch(this.item);

      this.loading = false;

      if (res.status == 401) {
        this.handleLogout();
      } else if (res.status == 200 || res.status == 201) {
        this.updateUser(res.data);
        bus.$emit("edit_save", { msg: "Saved successfully", data: res.data });
        this.$router.push(`/profile`);
      } else {
        this.snackbar = {
          show: true,
          text: res?.data?.msg
            ? res.data.msg
            : "An error occurred, could not save",
          color: `red`,
        };
        this.resetSnackbar();
      }
    },

    resetSnackbar() {
      setTimeout(() => {
        this.snackbar = {};
      }, 2000);
    },

    handleLogout() {
      this.$store.dispatch("auth/logout");
    },

    async checkAuthorization() {
      this.loading = true;
      let user = {
        email: this.$store.state.auth.user.email,
        password: this.password,
      };

      let service = new BaseService("login");
      let res;
      this.loading = true;

      res = await service.create(user);

      if (res.status == 200 || res.status == 201) {
        this.isNotAuthorised = false;
        this.loading = false;
        this.item = {
          name: res.data.name,
          id: res.data.id,
          email: res.data.email,
        };
      } else {
        this.loading = false;
        this.snackbar = {
          show: true,
          text: "Incorrect password! Please try again.",
          color: `red`,
        };
        this.resetSnackbar();
      }
    },
  },
};
</script>
